import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Getting your credit score is a great first step to start your credit journey. Best of all, checking your own credit score `}<strong parentName="p">{`does not`}</strong>{` hurt your credit. There are many ways to get your credit score and credit report for free. In this article, we list four ways to check your credit.`}</p>
    <h2>{`Annual Free Credit Report`}</h2>
    <p><img parentName="p" {...{
        "src": "https://myfabfinance.com/wp-content/uploads/2014/01/Step-1.jpg",
        "alt": "Annual Credit Report Website"
      }}></img></p>
    <figcaption>Annual Credit Report Website</figcaption>
    <p>{`You are entitled to one free copy of your credit report from `}<strong parentName="p">{`all three`}</strong>{` credit bureaus `}<strong parentName="p">{`every year`}</strong>{`. This is made possible by the Fair Credit Reporting Act (FCRA). The website `}<a parentName="p" {...{
        "href": "annualcreditreport.com"
      }}>{`annualcreditreport.com`}</a>{` is the only site authorized to issue free credit reports under FCRA. (I know the site looks sketchy but it is indeed the official website.)`}</p>
    <p><strong parentName="p">{`We recommend that you read through the other three methods of checking your credit score before requesting your free annual credit report.`}</strong></p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`Credit Card Account`}</h2>
    <p><img parentName="p" {...{
        "src": "https://fico.i.lithium.com/t5/image/serverpage/image-id/35296i150FE8EEFA4B8A99?v=1.0",
        "alt": "AMEX MyCredit Guide"
      }}></img></p>
    <figcaption>AMEX MyCredit Guide</figcaption>
    <p>{`If you have an existing credit card, whether it is yours or co-signed by others, you might have access to the free credit reporting service offered by the card issuer. If you are a Chase customer, you have access to their free, weekly-updated credit reporting service `}<a parentName="p" {...{
        "href": "https://creditcards.chase.com/free-credit-score"
      }}>{`Chase Credit Journey`}</a>{`. American Express has its own `}<a parentName="p" {...{
        "href": "https://www.americanexpress.com/us/credit-cards/features-benefits/free-credit-score/"
      }}>{`MyCredit Guide`}</a>{`. Discover, Capital One, and many others have their respective free credit reporting services. All of these services will only result in a soft pull, which won't show up on your credit report. Some of these services are available to you for free even if you aren't already an existing customer. It is also important to note that most of the scores offered through free services are educational (except FICO scores), not the actual score (FICO or proprietary) used by banks when they decide on your credit application.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`Credit Monitoring Service`}</h2>
    <p><img parentName="p" {...{
        "src": "https://fico.i.lithium.com/t5/image/serverpage/image-id/26989i819A048B22DA0AF7/image-size/original?v=1.0&px=-1",
        "alt": "Credit Karma"
      }}></img></p>
    <figcaption>Credit Karma</figcaption>
    <p>{`Even if you don't already have a credit card, chances are that you already have a credit history through your other borrowing activities. You can access your free weekly credit reports through personal finance websites like Credit Karma and NerdWallet. Like many banks' credit reporting services, using these third-party services will only result in a soft pull. These services also offer VantageScore 3.0, an educational score which is not used by banks to assess your creditworthiness. We recommend using these services at your own discretion as there might be privacy concerns associated with these services.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`When You Apply for Credit`}</h2>
    <p>{`Whenever you apply for new credit, whether it is a new credit card or loan, you are entitled to get a free copy of your credit report from the lender if your application resulted in a hard pull. Under FCRA, you can request your copy of the report within 60 days from the date you submitted your application. Usually, lenders will include your credit score when they notify you of their decision in writing.`}</p>
    <h2>{`Bottom Line`}</h2>
    <p>{`Whichever method you use, checking credit helps you understand where you currently stand so that you can set goals to build credit. We will be detailing steps to start building credit in future posts. In the meantime, feel free to play around with the simulators offered by the free credit reporting services.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      